<template>
  <div class="box">
    <!-- <div class="way" @click="handleRouter('addComFillType')">
      <h2>一般</h2>
      <p>普通数据常规报备，需要按一级等保要求管理</p>
      <div class="logo">
        <img src="../../../assets/img/一般.png" alt="" />
      </div>
    </div> -->
    <div class="way" @click="handleRouter('addFillType')">
      <h2>重要</h2>
      <p>数据一旦被泄露或篡改、损毁，可能直接危害国家安全、经济运行、社会稳定、公共健康和安全。</p>
      <div class="logo">
        <img src="../../../assets/img/importantIcon.png" alt="" />
      </div>
    </div>
    <div class="way" @click="handleRouter('addComFillType')">
      <h2>核心</h2>
      <!-- <p>数据可能涉及国家安全，需要按三级以上等保要求管理</p> -->
      <p>对领域、群体、区域具有较高覆盖度或达到较高精度、较大规模、一定深度的重要数据，主要包括直接关系国民经济命脉、重要民生、重大公共利益的数据。</p>
      <!-- <p>达到较高精度、较大规模、一定深度的重要数据，一旦被非法使用或共享，可能直接影响政治安全。</p> -->
      <div class="logo">
        <img src="../../../assets/img/coreIcon.png" alt="" />
      </div>
    </div>
   
  </div>
</template>
  
  <script setup>
import { useRouter } from "vue-router";
let router = useRouter();
const handleRouter = (name) => {
  router.push({
    name,
  });
};
</script>
  
  <style lang="scss" scoped>
.box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.way {
  background-color: #f6f6f6;
  border-radius: 6px;
  margin-right: 10px;
  padding: 0 20px;
  width: 250px;
  height: 390px;
  position: relative;
  border-radius: 20px;
  border: solid 3px #f6f6f6;
  box-sizing: border-box;
  h2{
    color: #555555;
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
    margin-top: 40px;
    font-weight: lighter;
  }
  p {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 30px;
    color: #555555;
    text-align: center;
  }
  .logo {
    position: absolute;
    left: 50%;
    bottom: -3px;
    transform: translate(-50%, 0);
    width: 90px;
    height: 100px;

    img {
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.way:nth-child(1) {
  .logo {
    background: #ff8503;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    img{
      width: 33px;
      height: 18px;
    }
  }
}
.way:nth-child(1):hover {
  border: 3px solid #ff8503;
  cursor: pointer;
  box-sizing: border-box;
  h2 {
    color: #111111;
    font-weight: bold;
  }
  .logo {
    height: 130px;
    img {
      animation: foudeIn 50ms linear;
      top: 30%;
      
    }
  }
}
.way:nth-child(2) {
  margin-right: 0;
  .logo {
    background: #f83435;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}
.way:nth-child(2):hover {
  border: 3px solid #f83435;
  cursor: pointer;
  box-sizing: border-box;
  h2 {
    color: #111111;
    font-weight: bold;
  }
  .logo {
    height: 130px;
    img {
      animation: foudeIn 50ms linear;
      top: 30%;
    }
  }
}

@keyframes foudeIn {
  from {
    top: 50%;
  }
  to {
    top: 30%;
  }
}
</style>